'use client'

import { Button, Cell, InputText, Spacer, Text } from '@vinted/web-ui'
import classNames from 'classnames'
import { camelCase } from 'lodash'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { getFingerprint } from '@marketplace-web/domain/audit'
import { useDataDomeCaptcha } from '@marketplace-web/domain/data-dome'
import { ErrorItem } from '@marketplace-web/shared/api-client'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { renderValidation, useFormValidationMessage } from '@marketplace-web/shared/ui-helpers'
import { isValueInObject } from '@marketplace-web/shared/utils'
import { changePassword } from 'data/api'

import ChangePasswordRestrictedModal from '../../components/ChangePasswordRestrictedModal'
import { isPasswordChangeRestricted } from '../../utils/errors'
import { ChangePasswordFormField } from './constants'
import { ChangePasswordFormModel } from './types'

type Props = {
  instructions: string
}

const ChangePassword = ({ instructions }: Props) => {
  const {
    setError,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm<ChangePasswordFormModel>({
    mode: 'onSubmit',
  })
  const getErrorMessage = useFormValidationMessage(errors, 'change_password.fields')
  const translate = useTranslate('change_password')
  const { user } = useSession()
  const breakpoints = useBreakpoint()
  const containerClass = classNames({ 'u-flexbox': breakpoints.desktops })
  const [isSuccess, setIsSuccess] = useState(false)
  const isCurrentPasswordVisible = !user?.is_login_via_external_system_only
  const [showPasswordChangeRestrictedModal, setShowPasswordChangeRestrictedModal] = useState(false)

  useEffect(() => {
    if (isSuccess) {
      reset()
    }
  }, [isSuccess, reset])

  const setErrors = (validationErrors: Array<ErrorItem>) => {
    validationErrors.forEach(({ field, value }) => {
      const fieldName = camelCase(field)

      if (!isValueInObject(fieldName, ChangePasswordFormField)) return

      setError(fieldName, { type: 'manual', message: value })
    })
  }

  const togglePasswordChangeRestrictedModal = () => {
    setShowPasswordChangeRestrictedModal(!showPasswordChangeRestrictedModal)
  }

  const handleFormSubmit = async (formData: ChangePasswordFormModel) => {
    if (!user?.id) return

    const response = await changePassword({
      userId: user.id,
      currentPassword: formData.oldPassword,
      newPassword: formData.password,
      fingerprint: await getFingerprint(),
    })

    if (isPasswordChangeRestricted(response)) {
      togglePasswordChangeRestrictedModal()

      return
    }

    if ('errors' in response) {
      setErrors(response.errors)

      return
    }

    setIsSuccess(true)
  }

  useDataDomeCaptcha(() => {
    handleFormSubmit(getValues())
  })

  const renderCurrentPasswordField = () => (
    <Controller
      defaultValue=""
      control={control}
      name={ChangePasswordFormField.CurrentPassword}
      rules={{ required: true }}
      render={({ field }) => (
        <InputText
          {...field}
          placeholder={translate('fields.old_password.title')}
          type={InputText.Type.Password}
          validation={renderValidation(getErrorMessage(ChangePasswordFormField.CurrentPassword))}
        />
      )}
    />
  )

  const renderNewPasswordField = () => (
    <Controller
      defaultValue=""
      control={control}
      name={ChangePasswordFormField.NewPassword}
      rules={{ required: true }}
      render={({ field }) => (
        <InputText
          {...field}
          placeholder={translate('fields.password.title')}
          type={InputText.Type.Password}
          validation={renderValidation(getErrorMessage(ChangePasswordFormField.NewPassword))}
        />
      )}
    />
  )

  const renderConfirmPasswordField = () => {
    const getPasswordConfirmationErrorMessage = getErrorMessage(
      ChangePasswordFormField.RepeatPassword,
      {
        validate: translate('fields.repeat_password.errors.validate', {
          field: translate('fields.password.title'),
        }),
      },
    )

    return (
      <Controller
        defaultValue=""
        control={control}
        name={ChangePasswordFormField.RepeatPassword}
        rules={{
          required: true,
          validate: value => value === getValues().password,
        }}
        render={({ field }) => (
          <InputText
            {...field}
            placeholder={translate('fields.repeat_password.title')}
            type={InputText.Type.Password}
            validation={renderValidation(getPasswordConfirmationErrorMessage)}
          />
        )}
      />
    )
  }

  const renderSubmitButton = () => (
    <Button
      text={translate('actions.submit')}
      type={Button.Type.Submit}
      styling={Button.Styling.Filled}
      disabled={isSubmitting || isSuccess}
      isLoading={isSubmitting}
      testId="change-password-button"
      onClick={(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        if (isSubmitting) {
          event.preventDefault()
        }
      }}
    />
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {isCurrentPasswordVisible && renderCurrentPasswordField()}
      {renderNewPasswordField()}
      {renderConfirmPasswordField()}
      {renderSubmitButton()}
    </form>
  )

  const renderSuccessMessage = () => {
    if (!isSuccess) return null

    return (
      <>
        <Spacer size={Spacer.Size.Large} />
        <Cell theme="success" title={translate('success_message.body')} />
      </>
    )
  }

  return (
    <>
      {showPasswordChangeRestrictedModal && (
        <ChangePasswordRestrictedModal onClose={togglePasswordChangeRestrictedModal} />
      )}
      <Text as="h1" text={translate('title')} type={Text.Type.Heading} />
      {renderSuccessMessage()}
      <Spacer size={Spacer.Size.Large} />
      <div className="u-clearfix">
        <div className={containerClass}>
          <Cell>
            <Text as="h2" text={translate('description')} type={Text.Type.Title} />
            <Text as="span" text={instructions} html />
          </Cell>
          <Cell>{renderForm()}</Cell>
        </div>
      </div>
    </>
  )
}

export default ChangePassword
