'use client'

import { useCallback, useMemo, useState } from 'react'

import { useSession } from '@marketplace-web/shared/session'

import AuthModalWithProvider from '../../components/AuthModal'
import { AuthModalContext } from './AuthModalContext'

enum AuthModalState {
  Standard = 'standard',
  Business = 'business',
  Closed = 'closed',
}

type Props = {
  children: React.ReactNode
}

const AuthModalProvider = ({ children }: Props) => {
  const userId = useSession().user?.id
  const [authModalState, setAuthModalState] = useState(AuthModalState.Closed)

  const openAuthModal = useCallback(({ isBusiness }: { isBusiness?: boolean } = {}) => {
    setAuthModalState(isBusiness ? AuthModalState.Business : AuthModalState.Standard)
  }, [])

  const closeAuthModal = useCallback(() => {
    setAuthModalState(AuthModalState.Closed)
  }, [])

  const contextValue = useMemo(() => {
    return {
      openAuthModal,
      closeAuthModal,
      isAuthModalOpen: authModalState !== AuthModalState.Closed,
      isBusinessAuth: authModalState === AuthModalState.Business,
    }
  }, [openAuthModal, closeAuthModal, authModalState])

  return (
    <AuthModalContext.Provider value={contextValue}>
      {children}
      {!userId && <AuthModalWithProvider />}
    </AuthModalContext.Provider>
  )
}

export default AuthModalProvider
