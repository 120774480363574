'use client'

import { Button, Cell, InputText, Spacer, Text } from '@vinted/web-ui'
import { camelCase } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { getFingerprint } from '@marketplace-web/domain/audit'
import { useDataDomeCaptcha } from '@marketplace-web/domain/data-dome'
import { ErrorItem } from '@marketplace-web/shared/api-client'
import { useLocation } from '@marketplace-web/shared/browser'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { renderValidation, useFormValidationMessage } from '@marketplace-web/shared/ui-helpers'
import { isValueInObject, normalizedQueryParam } from '@marketplace-web/shared/utils'

import { changeForgotPassword, getForgotPasswordConfirmationCode } from 'data/api'
import { ResponseCode } from 'data/api/response-codes'

import ChangePasswordRestrictedModal from '../../components/ChangePasswordRestrictedModal'
import { isPasswordChangeRestricted } from '../../utils/errors'
import { ChangeForgotPasswordFormField } from './constants'
import { ChangeForgotPasswordFormModel } from './types'

const ChangeForgotPassword = () => {
  const {
    setError,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<ChangeForgotPasswordFormModel>({
    mode: 'onSubmit',
  })
  const [isInitialized, setIsInitialized] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [secondConfirmationCode, setSecondConfirmationCode] = useState<string | null>(null)
  const [showPasswordChangeRestrictedModal, setShowPasswordChangeRestrictedModal] = useState(false)

  const getErrorMessage = useFormValidationMessage(errors, 'change_forgot_password.fields')

  const { searchParams } = useLocation()
  const translate = useTranslate('change_forgot_password')
  const code = normalizedQueryParam(searchParams.code)

  useEffect(() => {
    const fetchSecondConfirmationCode = async () => {
      if (!code) return

      const response = await getForgotPasswordConfirmationCode({ code })

      if ('errors' in response) return

      setSecondConfirmationCode(response.confirmation_code)
    }

    fetchSecondConfirmationCode().then(() => {
      setIsInitialized(true)
    })
  }, [code])

  const setErrors = (validationErrors: Array<ErrorItem>) => {
    validationErrors.forEach(({ field, value }) => {
      const fieldName = camelCase(field)

      if (!isValueInObject(fieldName, ChangeForgotPasswordFormField)) return

      setError(fieldName, { type: 'manual', message: value })
    })
  }

  const togglePasswordChangeRestrictedModal = () => {
    setShowPasswordChangeRestrictedModal(!showPasswordChangeRestrictedModal)
  }

  const handleFormSubmit = async (formData: ChangeForgotPasswordFormModel) => {
    if (!secondConfirmationCode) return

    const response = await changeForgotPassword({
      code: secondConfirmationCode,
      password: formData.password,
      passwordConfirmation: formData.passwordConfirmation,
      fingerprint: await getFingerprint(),
    })

    if (isPasswordChangeRestricted(response)) {
      togglePasswordChangeRestrictedModal()

      return
    }

    if ('errors' in response) {
      if (response.errors.length) {
        setErrors(response.errors)
      }

      if (response.code === ResponseCode.NotFound) {
        setSecondConfirmationCode(null)
      }

      return
    }

    setIsSuccess(true)
  }

  useDataDomeCaptcha(() => handleSubmit(handleFormSubmit)())

  const renderSubmitButton = () => (
    <Button
      text={translate('actions.submit')}
      type={Button.Type.Submit}
      styling={Button.Styling.Filled}
      isLoading={isSubmitting}
      disabled={isSubmitting}
      testId="change-forgot-password-button"
      onClick={(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        if (isSubmitting) {
          event.preventDefault()
        }
      }}
    />
  )

  const renderSuccessMessage = () => (
    <>
      <Spacer size={Spacer.Size.Large} />
      <Cell
        theme="success"
        title={translate('success_message')}
        testId="change-forgot-password-success"
      />
    </>
  )

  const renderForm = () => {
    if (isSuccess) return renderSuccessMessage()

    return (
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <InputText
          {...register(ChangeForgotPasswordFormField.Password, {
            required: true,
          })}
          placeholder={translate('fields.password.title')}
          type={InputText.Type.Password}
          validation={renderValidation(getErrorMessage(ChangeForgotPasswordFormField.Password))}
          testId="change-forgot-password-enter-new"
        />
        <InputText
          {...register(ChangeForgotPasswordFormField.PasswordConfirmation, {
            required: true,
          })}
          placeholder={translate('fields.password_confirmation.title')}
          type={InputText.Type.Password}
          validation={renderValidation(
            getErrorMessage(ChangeForgotPasswordFormField.PasswordConfirmation),
          )}
          testId="change-forgot-password-reenter-new"
        />
        {renderSubmitButton()}
      </form>
    )
  }

  if (!isInitialized) {
    return null
  }

  if (!secondConfirmationCode) {
    return (
      <div className="box box--form">
        <Text
          as="h1"
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
          type={Text.Type.Heading}
          text={translate('errors.cant_change_password')}
        />
        <Spacer size={Spacer.Size.Large} />
        <Text
          as="span"
          text={translate('errors.reset_link_expired')}
          testId="change-forgot-password-link-expired"
        />
      </div>
    )
  }

  return (
    <>
      {showPasswordChangeRestrictedModal && (
        <ChangePasswordRestrictedModal onClose={togglePasswordChangeRestrictedModal} />
      )}
      <div className="box box--form">
        <Text
          as="h1"
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
          type={Text.Type.Heading}
          text={translate('title')}
        />
        <Spacer size={Spacer.Size.Large} />
        {renderForm()}
      </div>
    </>
  )
}

export default ChangeForgotPassword
