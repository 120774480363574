'use client'

import { Card, Spacer } from '@vinted/web-ui'

import { ComponentLocation } from '../../observability/constants'
import Auth from '../../components/Auth'

const SelectLoginType = () => {
  return (
    <>
      <Spacer size={Spacer.Size.X2Large} />
      <div className="auth__container">
        <Card>
          <Spacer size={Spacer.Size.X2Large} />
          <Auth componentLocation={ComponentLocation.AuthenticationPage} />
        </Card>
      </div>
    </>
  )
}

export default SelectLoginType
