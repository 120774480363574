import { withServerSideProxy } from '@marketplace-web/shared/ssr'

export const { getBrowserWidth, getBrowserHeight } = withServerSideProxy(
  {
    getBrowserWidth: () => window.innerWidth,
    getBrowserHeight: () => window.innerHeight,
  },
  {
    getBrowserWidth: () => 0,
    getBrowserHeight: () => 0,
  },
)
