import { Button, Dialog } from '@vinted/web-ui'

type Props = {
  onClose: () => void
}

const ChangePasswordRestrictedModal = ({ onClose }: Props) => {
  return (
    <Dialog
      show
      title="Can't create password"
      body="This account has a special role, so for security reasons it can’t have a password."
      actions={[{ text: 'Close', callback: onClose, style: Button.Styling.Filled }]}
    />
  )
}

export default ChangePasswordRestrictedModal
